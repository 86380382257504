import React, { ElementType } from 'react'
import Link from 'next/link'

import classes from './Button.module.scss'

type TButton = {
  text: string
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
  Icon?: ElementType
  IconLeft?: ElementType
  counter?: number
  theme?:
    | 'primary'
    | 'secondary'
    | 'contrast'
    | 'transparent'
    | 'stroke'
    | 'colored'
    | 'stroke_secondary'
    | 'text'
  disabled?: boolean
  rotateIcon?: boolean
  active?: boolean
  type?: 'button' | 'submit' | 'link'
  href?: string
  styles?: React.CSSProperties
  customClasses?: any
}

const Button: React.FC<TButton> = ({
  text,
  onClick,
  Icon,
  IconLeft,
  counter,
  theme = 'primary',
  disabled = false,
  active = false,
  rotateIcon = false,
  type = 'button',
  styles,
  href,
  customClasses,
}) => {
  let rootClasses = classes.root
  let iconClasses = classes.icon

  if (theme === 'primary') {
    rootClasses += ` ${classes.root_primary}`
  }

  if (theme === 'secondary') {
    rootClasses += ` ${classes.root_secondary}`
  }

  if (theme === 'contrast') {
    rootClasses += ` ${classes.root_contrast}`
  }

  if (theme === 'transparent') {
    rootClasses += ` ${classes.root_transparent}`
  }

  if (theme === 'stroke') {
    rootClasses += ` ${classes.root_stroke}`
  }

  if (theme === 'stroke_secondary') {
    rootClasses += ` ${classes.root_stroke}`
    rootClasses += ` ${classes.root_stroke_secondary}`
    if (active) {
      rootClasses += ` ${classes.root_stroke_secondary_active}`
    }
  }

  if (theme === 'colored') {
    rootClasses += ` ${classes.root_colored}`
    if (active) {
      rootClasses += ` ${classes.root_colored_active}`
    }
  }

  if (theme === 'text') {
    rootClasses += ` ${classes.root_text}`
  }

  if (active) {
    rootClasses += ` ${classes.root_active}`
  }

  if (rotateIcon) {
    iconClasses += ` ${classes.icon_flipped}`
  }

  if (type === 'link' && disabled) {
    rootClasses += ` ${classes.root_disabled}`
  }

  if (customClasses) {
    rootClasses += ` ${customClasses}`
  }

  if (type !== 'link') {
    return (
      <button
        type={type}
        className={rootClasses}
        onClick={onClick}
        disabled={disabled}
        style={styles || {}}
      >
        {IconLeft && <IconLeft className={iconClasses} />}
        {text}
        {Icon && <Icon className={iconClasses} />}
        {counter && <span className={classes.counter}>{counter}</span>}
      </button>
    )
  }

  return (
    <Link
      className={rootClasses}
      href={href || ''}
      style={styles || {}}
      replace={true}
      prefetch={false}
      passHref
      draggable="false"
      onClick={onClick}
    >
      {IconLeft && <IconLeft className={iconClasses} />}
      {text}
      {Icon && <Icon className={iconClasses} />}
      {counter && <span className={classes.counter}>{counter}</span>}
    </Link>
  )
}

export default Button
